import * as Sentry from "@sentry/gatsby"
let activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

if (process.env.GATSBY_CLOUD && process.env.BRANCH !== "production") {
  activeEnv = "staging"
}

const uaDenyList = ["Catchpoint", "HeadlessChrome"]

const shouldSuppressEvent = () => {
  const ua =
    typeof window !== "undefined" && window.navigator && window.navigator.userAgent ? window.navigator.userAgent : null

  // If User Agent includes any blocked strings don't send the event
  return uaDenyList.some((deny) => ua.includes(deny))
}

let releaseName = process.env.TRAVIS
  ? `${process.env.TRAVIS_COMMIT}-${process.env.TRAVIS_BUILD_NUMBER}`
  : process.env.GATSBY_CLOUD
  ? // TODO: Gatsby Cloud doesn't expose anything we can use as a unique release name?
    // see https://gatsby.canny.io/gatsby-cloud/p/expose-git-commit-sha
    new Date().toISOString()
  : "development"

Sentry.init({
  dsn: "https://d879b4c80908473fa9394fc34fd84ec7@o1025883.ingest.sentry.io/6048310",
  sampleRate: 0.5,
  release: releaseName,
  environment: activeEnv,
  denyUrls: [
    // Cookie banner
    "cdn.cookielaw.org",
    // Chrome extensions
    "chrome://",
    // Mutiny
    "client.mutinycdn.com",
    "client-registry.mutinycdn.com",
    // SpeedCurve
    "cdn.speedcurve.com",
  ],
  idleTimeout: 2500,
  beforeSend: (event) => {
    if (shouldSuppressEvent()) {
      return null
    }

    if (window.mutiny?.client?.experiences) {
      const { experiences } = window.mutiny.client
      const audienceNames = experiences
        .map((e) => e?.audienceSegment?.name)
        .filter((n) => !!n)
        .join("::")
      const experienceData = experiences
        .map(
          (e) => `${e?.experience?.description || "No description"} (${e?.experience?.variation_name || "No variant"})`
        )
        .join("::")
      Sentry.setTag("mutiny.audience", audienceNames)
      Sentry.setTag("mutiny.experience", experienceData)
    } else {
      Sentry.setTag("mutiny.audience", "none")
      Sentry.setTag("mutiny.experience", "none")
    }

    return event
  },
})
